// Danish
export default{
    form: {
        startSurveyButton: "Lad os starte",
        nextButton: "Næste",
        submitAnswerButton: "Indsend svar",
        continueButton: "Fortsæt",
        finishButton: "Afslut",
        headerPercentageCompletion: 'fuldført',
        headerQuestionsCompleted: 'spørgsmål',
        headerDefaultErrorMessage: 'Ups! Noget gik galt.',
        brandingPoweredBy: 'Drevet af',
        brandingPromoWhySurvey: 'Hvorfor lave en undersøgelse, når du kan bruge <b>{product}</b>?',
        brandingPromoGetStarted: 'Kom i gang i dag!',
        submissionPending: "Voiceform indsendes. Luk venligst ikke browseren.",
        submissionCanCloseWindow: "Din voiceform er blevet indsendt. Du kan nu lukke vinduet 👍",
        mediaAttachmentOverlayCloseButton: "Luk",
        expiredFormHeader: "Tak for besøget!",
        expiredFormMessage: "Det ser ud til, at denne undersøgelse er udløbet. Kontakt venligst voiceform-skaberen for at informere dem.",
        notPublishedFormHeader: "Voiceform er ikke offentliggjort!",
        notPublishedFormMessage: "Det ser ud til, at denne undersøgelse ikke er offentliggjort. Kontakt venligst voiceform-skaberen for at informere dem.",
        notPublishedFormRetryButton: "Prøv igen",
        errorFormHeader: "Ups!",
        errorFormMessage: "Noget gik muligvis galt. Prøv venligst igen senere.",
        errorFormRetryButton: "Prøv igen",
        emptyFormHeader: "Tak for besøget!",
        emptyFormMessage: "Ups! Dette formular ser ud til at være tomt uden spørgsmål eller felter at udfylde. Vi beklager ulejligheden. Kontakt venligst skaberen af denne formular for at informere dem om problemet. Tak for din forståelse og samarbejde!",

        restoreFormHeader: "Det ser ud til, at du har en formular i gang!",
        restoreFormMessage: "Vil du fortsætte, hvor du slap?",
        restoreFormButton: "Fortsæt formular",
        restoreFormButtonNew: "Start forfra",

        audioPermissionHeader: "Vil du svare med din stemme?",
        audioPermissionMessage: "At tale vil gøre det hurtigt og nemt at svare.",
        audioPermissionAcceptButton: "Ja, det vil jeg",
        audioPermissionDenyButton: "Nej tak",
        audioPermissionErrorHeader: "Mikrofon er ikke tilgængelig",
        audioPermissionErrorMessage: "Kontroller venligst dine browserindstillinger for at give adgang til mikrofonen.",
        audioPermissionErrorButton: "Okay",
        audioRecorderFailedUpload: "Kunne ikke uploade optagelsen",
        silenceDetectorError: "Vi kan ikke høre dig. Tjek venligst dine mikrofonindstillinger. Tip: Det kan være din eksterne mikrofon.",
        audioRecorderButtonRecord: "Optag",
        audioRecorderButtonRecordMore: "Optag mere",
        voiceResponseSelectionMessage: "Vælg, hvordan du vil svare...",
        voiceResponseSelectionOrDivider: "eller",
        voiceResponseTextInputPlaceholder: "Skriv svar",
        voiceResponseTextInputCharacterCounterMinMessage: "Indtast venligst mindst {min} tegn",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} tegn (minimum {min} tegn)",
        voiceResponseTextInputCharacterValidationMessage: "{count} tegn (minimum {min} tegn)",

        // checkbox
        checkboxValidationTooFew: "Du skal vælge mindst {min} valgmulighed(er)",
        checkboxValidationTooMany: "Du må vælge maksimalt {max} valgmulighed(er)",
        checkboxNoneOfTheAboveOption: "Ingen af ovenstående",
        checkboxInvalidAnswer: "Ugyldigt svar.",

        // datepicker
        datePickerPlaceholder: "Vælg dato",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "Ugyldig e-mail.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Optag video",
        fileUploadPluginScreenCast: "Skærmoptagelse",
        fileUploadVideoImportFilesDefault: 'Optag eller upload en video via:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Vælg en videooptagelsesmulighed:',
        fileUploadVideoImportFilesNoCamera: 'Tryk på en knap for at optage skærmvideo',
        fileUploadVideoImportFilesNoScreenCast: 'Tryk på en knap for at optage video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Ingen muligheder tilgængelige for at optage video',

        // matrix
        matrixValidationMessage: "Svaret er gyldigt.",
        matrixRow: "Række",
        matrixColumn: "Kolonne",
        matrixRowRequired: "Række {rowTitle} er påkrævet.",
        matrixRadioGroup: "Radiogruppe",
        matrixCheckboxGroup: "Afkrydsningsgruppe",
        matrixGroupRequired: "For række {rowTitle}. {type} {groupTitle} er påkrævet.",
        matrixColumnRequired: "For række {rowTitle}. Kolonne {columnTitle} er påkrævet.",
        matrixColumnInvalid: "For række {rowTitle}. Kolonne {columnTitle} er ugyldig.",
        matrixRequired: "Påkrævet.",
        matrixNumericMustBeNumber: "Skal være et tal.",
        matrixNumericMustBeGreaterThenMin: "Skal være større end {min}.",
        matrixNumericMustBeLessThenMax: "Skal være mindre end {max}.",
        matrixNumericMustBeInteger: "Skal være et heltal.",
        matrixNumericInvalidNumber: "Ugyldigt nummer.",

        // name
        nameLabel: "Navn",
        nameInvalid: "Angiv fornavn og efternavn.",

        // nps
        npsNotLikely: "Ikke særlig sandsynligt",
        npsExtremelyLikely: "Meget sandsynligt",

        // numeric input
        numericInputRequired: "Påkrævet.",
        numericInputMustBeNumber: "Skal være et tal.",
        numericInputMustBeGreaterThenMin: "Skal være større end {min}.",
        numericInputMustBeLessThenMax: "Skal være mindre end {max}.",
        numericInputMustBeInteger: "Skal være et heltal.",
        numericInputInvalidNumber: "Ugyldigt nummer.",
        numericInputPlaceholder: "Indtast tal",

        // phone
        phoneInvalid: "Telefonnummeret er ugyldigt.",
        phoneCountrySelectorLabel: 'Landekode',
        phoneCountrySelectorError: 'Vælg land',
        phoneNumberLabel: 'Telefonnummer',
        phoneExample: 'Eksempel:',

        // boolean
        booleanYesLabel: "Ja",
        booleanNoLabel: "Nej",

        //questionStep
        questionStepAudioQuestionLabel: "Lydspørgsmål",

        // errors
        invalidPhoneNumber: "{phone} er et ugyldigt telefonnummer.",
        invalidEmail: "{email} er en ugyldig e-mailadresse.",
        questionIsRequired: "Spørgsmålet er påkrævet.",
        answerIsNotValid: "Svaret er ikke gyldigt.",
        unfinishedProbingDialogError: "Udfyld venligst dialogen.",
        cannotResumePartialResponse: "Kan ikke genoptage delvist svar.",
        failedToSubmitForm: "Kunne ikke sende formularen. Tjek din internetforbindelse og prøv igen."
    }
}
